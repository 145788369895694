<template>
  <form>
    <AuthSignupUser @done="step = 2" v-if="step === 1" key="user" />
    <AuthSignupOrg
      @back="step = 1"
      @done="complete($event)"
      v-if="step === 2"
      key="org"
    />
    <div key="success" v-if="step === 3">
      <p class="title is-4 has-text-info">Account created!</p>
      <p class="subtitle is-6 has-text-dark">
        You can now login using the credentials provided.
      </p>
      <div class="buttons">
        <button
          class="button is-rounded is-soft has-text-white"
          @click="goToLogin"
        >
          <span>Login now</span>
          <span class="icon">
            <i class="fas fa-chevron-circle-right" />
          </span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'AuthSignup',
  components: {
    AuthSignupUser: () => import('./AuthSignupUser'),
    AuthSignupOrg: () => import('./AuthSignupOrg')
  },
  data: () => ({ step: 1 }),
  methods: {
    complete() {
      this.step = 3
      window.dataLayer.push({ event: 'PortalSignup' })
    },
    goToLogin() {
      this.$router.push({ name: 'auth-login' })
    }
  }
}
</script>
